import { AttachMoney as AttachMoneyIcon, AttachMoneySharp, CalendarToday as CalendarTodayIcon, LocalShipping as LocalShippingIcon, ShoppingCart as ShoppingCartIcon } from '@mui/icons-material';
import { Box, Card, CardContent, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchUserStatistics } from '../Firebase_Manager/Data_Manager';
import { ContentWrapper } from '../Styles/User_Dashboard_Styles';

const StatCard = ({ icon: Icon, title, value }) => {
  const { t } = useTranslation();
  
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Tooltip title={t(`${title}_tooltip`)} placement="top" arrow>
        <Card 
          component={motion.div}
          whileHover={{ scale: 1.05, boxShadow: '0 8px 16px rgba(0,0,0,0.1)' }}
          whileTap={{ scale: 0.95 }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          sx={{
            borderRadius: '16px',
            background: 'linear-gradient(145deg, #ffffff 0%, #fff3e0 100%)',
            boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
            overflow: 'hidden',
            height: '100%'
          }}
        >
          <CardContent sx={{ p: 3 }}>
            <Box 
              display="flex" 
              flexDirection="column" 
              alignItems="center"
              sx={{ position: 'relative' }}
            >
              <Box
                sx={{
                  background: 'rgba(230, 81, 0, 0.1)', 
                  borderRadius: '50%',
                  p: 2,
                  mb: 2
                }}
              >
                <Icon 
                  sx={{ 
                    fontSize: 40,
                    color: '#f8842b',
                    filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
                  }} 
                />
              </Box>
              <Typography 
                variant="h6" 
                component="div"
                sx={{
                  fontWeight: 500,
                  color: '#666',
                  mb: 1
                }}
              >
                {t(title)}
              </Typography>
              <Typography 
                variant="h4" 
                component="div"
                sx={{
                  fontWeight: 600,
                  color: '#f8842b',
                  textShadow: '0 1px 2px rgba(0,0,0,0.1)'
                }}
              >
                {value}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Tooltip>
    </Grid>
  );
};

const Home = ({ currentUserUid }) => {
  const { t } = useTranslation();
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getStatistics = async () => {
      try {
        const stats = await fetchUserStatistics(currentUserUid);
        setStatistics(stats);
      } catch (error) {
        console.error('Error fetching statistics:', error);
      } finally {
        setLoading(false);
      }
    };

    getStatistics();
  }, [currentUserUid]);

  const statCards = useMemo(() => [
    { icon: ShoppingCartIcon, title: 'total_orders', value: statistics?.totalOrders },
    { icon: AttachMoneyIcon, title: 'total_spent_tnd', value: statistics?.totalPriceInTnd?.toFixed(2) },
    { icon: AttachMoneySharp, title: 'total_reste_too_pay', value: statistics?.totalResteToPay?.toFixed(2) },
    { icon: LocalShippingIcon, title: 'orders_delivered', value: statistics?.ordersDelivered },
    { icon: CalendarTodayIcon, title: 'last_order_date', value: statistics?.lastOrderDate ? new Date(statistics.lastOrderDate.toDate()).toLocaleDateString() : t('no_orders_found') },
  ], [statistics, t]);

  return (
    <ContentWrapper>
      <Typography 
        variant="h4" 
        gutterBottom
        sx={{
          fontWeight: 700,
          color: '#f8842b',
          mb: 4,
          textShadow: '0 2px 4px rgba(0,0,0,0.1)',
          position: 'relative',
          '&:after': {
            content: '""',
            position: 'absolute',
            bottom: -8,
            left: 0,
            width: '60px',
            height: '4px',
            background: 'linear-gradient(90deg, #f8842b 0%, #ffb74d 100%)',
            borderRadius: '2px'
          }
        }}
      >
        {t('client_dashboard')}
      </Typography>
      {loading ? (
        <Box 
          display="flex" 
          justifyContent="center" 
          alignItems="center" 
          minHeight={300}
          sx={{
            background: 'rgba(255,255,255,0.8)',
            borderRadius: '20px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
          }}
        >
          <CircularProgress 
            size={60}
            thickness={4}
            sx={{
              color: '#f8842b'
            }}
          />
        </Box>
      ) : (
        <Grid container spacing={4}>
          {statCards.map((card) => (
            <StatCard key={card.title} {...card} />
          ))}
        </Grid>
      )}
    </ContentWrapper>
  );
};

export default Home;
