import React from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import './SliderSection.css';
import { Link } from 'react-scroll';

const SliderSection = () => {
  return (
    <section className="slider_section">
      <Carousel id="customCarousel1" interval={3000}>
        {carouselItems.map((item, index) => (
          <Carousel.Item key={index}>
            <Container fluid>
              <Row className="justify-content-center">
                <Col xs={12} md={10}>
                  <div className="img-box">
                    <img src={item.image} alt={item.altText} />
                  </div>
                  <div className="btn-box">
                    {item.isLink ? (
                      <Link to={item.link} className="btn1" smooth={true} duration={100}>
                        {item.buttonText}
                      </Link>
                    ) : (
                      <a href={item.href} target={item.target} className="btn1">{item.buttonText}</a>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  );
};

const carouselItems = [
  {
    image: 'images/sites-around-tunisie.webp',
    altText: "Discover Tunisia's Sites",
    buttonText: 'استكشف الآن',
    isLink: true,
    link: 'services',
  },
  {
    image: 'images/track-order.webp',
    altText: 'Track Your Order',
    buttonText: 'تتبع طلبك',
    isLink: false,
    href: 'tracking',
    target: '_blank',
  },
  {
    image: 'images/price-finder.webp',
    altText: 'Check Our Prices',
    buttonText: 'معرفة اسعارنا',
    isLink: false,
    href: 'https://price.aminejameli.com',
    target: '_blank',
  },
  {
    image: 'images/delivery.webp',
    altText: 'Fast Delivery',
    buttonText: 'توصيل سريع',
    isLink: false,
    href: '#',
  },
  {
    image: 'images/review.webp',
    altText: 'We Value Your Feedback',
    buttonText: 'أرسل رأيك',
    isLink: true,
    link: 'feedbacks',
  },
  {
    image: 'images/faq.webp',
    altText: 'We answer your question',
    buttonText: 'اجابة على كل الاسئلة',
    isLink: true,
    link: 'faqs',
  },
  {
    image: 'images/offers.webp',
    altText: 'Special Offers',
    buttonText: 'اطلع على العروض',
    isLink: false,
    href: 'https://price.aminejameli.com',
    target: '_blank',
  },
];

export default SliderSection;
