import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import { signOut } from 'firebase/auth';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../config/firebaseConfig';

const Header = ({ user }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userMenuAnchor, setUserMenuAnchor] = useState(null);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuOpen = (event) => {
    setUserMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchor(null);
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/');
      handleUserMenuClose();
    });
  };

  return (
    <AppBar 
      position="fixed"
      sx={{
        backgroundColor: 'rgba(248, 132, 43, 0.95)',
        backdropFilter: 'blur(8px)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
        borderRadius: '0 0 20px 20px',
        transform: 'translateZ(0)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
    >
      <Toolbar sx={{ minHeight: '80px' }}>
        <IconButton 
          edge="start"
          color="inherit"
          aria-label={t('menu')}
          onClick={handleMenuOpen}
          sx={{ 
            mr: 2,
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.2)'
            }
          }}
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
          <a href='/' style={{ textDecoration: 'none' }}>
            <img 
              src="images/amine_jameli.png"
              alt={t('logo_alt_text')}
              style={{ 
                height: '70px',
                width: '70px', 
                objectFit: 'contain',
                transition: 'transform 0.3s ease',
                cursor: 'pointer',
                padding: '8px',
                borderRadius: '50%',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              }}
              onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
              onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
            />
          </a>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {user ? (
            <>
              <IconButton
                color="inherit"
                aria-label={t('user_menu')}
                onClick={handleUserMenuOpen}
                sx={{
                  padding: '8px',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)'
                  }
                }}
              >
                <AccountCircle sx={{ fontSize: 32 }} />
              </IconButton>
              <Menu
                anchorEl={userMenuAnchor}
                open={Boolean(userMenuAnchor)}
                onClose={handleUserMenuClose}
                PaperProps={{
                  sx: {
                    backgroundColor: '#fff',
                    borderRadius: '12px',
                    mt: 1.5,
                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                  }
                }}
              >
                <MenuItem onClick={() => { navigate('/me'); handleUserMenuClose(); }}>
                  {t('my_profile')}
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  {t('logout')}
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Button 
              color="inherit" 
              href="/me"
              sx={{
                borderRadius: '25px',
                border: '2px solid white',
                padding: '8px 24px',
                fontSize: '1rem',
                fontWeight: 500,
                textTransform: 'none',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: 'white',
                  color: '#f8842b',
                  transform: 'translateY(-2px)'
                }
              }}
            >
              {t('signup')}
            </Button>
          )}
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            sx: {
              backgroundColor: '#fff',
              color: '#000',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
              borderRadius: '12px',
              mt: 1.5,
              padding: '8px'
            },
          }}
        >
          <MenuItem 
            onClick={handleMenuClose}
            component="a"
            href="/"
            sx={{ 
              borderRadius: '8px', 
              margin: '4px',
              padding: '10px 20px',
              '&:hover': { 
                backgroundColor: '#fff4e6',
                color: '#f8842b'
              } 
            }}
          >
            {t('home')}
          </MenuItem>
          <MenuItem
            onClick={handleMenuClose}
            component="a"
            href="/tracking"
            sx={{ 
              borderRadius: '8px',
              margin: '4px',
              padding: '10px 20px',
              '&:hover': { 
                backgroundColor: '#fff4e6',
                color: '#f8842b'
              } 
            }}
          >
            {t('trackOrder')}
          </MenuItem>
          <MenuItem
            onClick={handleMenuClose}
            component="a"
            href="/feedbacks"
            sx={{ 
              borderRadius: '8px',
              margin: '4px', 
              padding: '10px 20px',
              '&:hover': { 
                backgroundColor: '#fff4e6',
                color: '#f8842b'
              } 
            }}
          >
            {t('client_feedbacks')}
          </MenuItem>
          <MenuItem
            onClick={handleMenuClose}
            component="a"
            href="https://price.aminejameli.com/contact"
            target="_blank"
            sx={{ 
              borderRadius: '8px',
              margin: '4px',
              padding: '10px 20px',
              '&:hover': { 
                backgroundColor: '#fff4e6',
                color: '#f8842b'
              } 
            }}
          >
            {t('contact_us')}
          </MenuItem>
          <MenuItem
            onClick={handleMenuClose}
            component="a"
            href="https://chat.cxgenie.ai?aid=4506fd38-76f9-4f14-9536-bbd43c76d75c&lang=ar"
            target="_blank" 
            sx={{ 
              borderRadius: '8px',
              margin: '4px',
              padding: '10px 20px',
              '&:hover': { 
                backgroundColor: '#fff4e6',
                color: '#f8842b'
              } 
            }}
          >
            {t('help_chatbot')}
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
