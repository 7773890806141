import {
  ContentCopy as ContentCopyIcon,
  Info as InfoIcon,
  Language as LanguageIcon,
  LockReset as LockResetIcon
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  Typography,
  styled,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { auth } from '../../../config/firebaseConfig';

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  '&:hover': {
    boxShadow: '0 8px 30px rgba(0,0,0,0.15)',
  },
  transition: 'all 0.3s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    borderRadius: theme.shape.borderRadius,
  }
}));

const StyledButton = styled('button')(({ theme }) => ({
  backgroundColor: '#f8842b',
  color: theme.palette.common.white,
  padding: '0.8rem 1.5rem',
  border: 'none',
  borderRadius: '25px',
  fontSize: '1rem',
  fontWeight: 500,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: '0.8rem',
  transition: 'all 0.3s ease',
  boxShadow: '0 4px 15px rgba(248,132,43,0.2)',
  '&:hover': {
    backgroundColor: '#e65100',
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 20px rgba(248,132,43,0.3)',
  },
  '&:active': {
    transform: 'translateY(1px)',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    justifyContent: 'center',
    fontSize: '0.9rem',
    padding: '0.6rem 1rem'
  }
}));

const InfoSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff3e0',
  padding: theme.spacing(3),
  borderRadius: '15px',
  border: '1px solid rgba(248,132,43,0.2)',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 4px 15px rgba(0,0,0,0.05)',
    transform: 'translateY(-2px)'
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    borderRadius: '10px'
  }
}));

const Settings = ({ user, userData }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [email, setEmail] = useState(user?.email || '');
  const [language, setLanguage] = useState(i18n.language);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const handlePasswordReset = () => {
    if (!email) {
      setSnackbar({
        open: true,
        message: t('please_provide_email'),
        severity: 'error'
      });
      return;
    }

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSnackbar({
          open: true,
          message: t('password_reset_email_sent'),
          severity: 'success'
        });
      })
      .catch((err) => {
        console.error('Error sending password reset email:', err);
        setSnackbar({
          open: true,
          message: t('error_sending_password_reset_email'),
          severity: 'error'
        });
      });
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    setSnackbar({
      open: true,
      message: t('language_changed'),
      severity: 'success'
    });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setSnackbar({
          open: true,
          message: t('copied_to_clipboard'),
          severity: 'success'
        });
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
        setSnackbar({
          open: true,
          message: t('failed_to_copy'),
          severity: 'error'
        });
      });
  };

  return (
    <Box sx={{ 
      maxWidth: '800px', 
      margin: '0 auto', 
      padding: { xs: '12px', sm: '24px' }
    }}>
      <StyledCard>
        <CardContent sx={{ 
          padding: { xs: '16px', sm: '32px' }
        }}>
          <Stack spacing={{ xs: 2, sm: 4 }}>
            {/* Header */}
            <Typography 
              variant={isMobile ? "h5" : "h4"}
              fontWeight="bold" 
              color="#f8842b"
              sx={{
                position: 'relative',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  bottom: -8,
                  left: 0,
                  width: '60px',
                  height: '4px',
                  background: 'linear-gradient(90deg, #f8842b 0%, #ffb74d 100%)',
                  borderRadius: '2px'
                }
              }}
            >
              {t('settings')}
            </Typography>

            {/* User Information Section */}
            <Box>
              <Stack direction="row" alignItems="center" spacing={2} mb={3}>
                <InfoIcon sx={{ 
                  color: '#f8842b', 
                  fontSize: { xs: '24px', sm: '28px' } 
                }} />
                <Typography 
                  variant={isMobile ? "h6" : "h5"} 
                  fontWeight="600" 
                  color="#424242"
                >
                  {t('user_information')}
                </Typography>
              </Stack>

              <InfoSection>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack 
                      direction={{ xs: 'column', sm: 'row' }} 
                      alignItems={{ xs: 'flex-start', sm: 'center' }} 
                      justifyContent="space-between"
                      spacing={1}
                    >
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          fontSize: { xs: '0.9rem', sm: '1.1rem' }, 
                          color: '#424242',
                          wordBreak: 'break-all'
                        }}
                      >
                        <strong>{t('user_uid')}:</strong> {user.uid}
                      </Typography>
                      <IconButton
                        size={isMobile ? "medium" : "large"}
                        onClick={() => copyToClipboard(user.uid)}
                        sx={{
                          color: '#f8842b',
                          '&:hover': {
                            backgroundColor: 'rgba(248,132,43,0.1)'
                          }
                        }}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Stack>
                  </Grid>
                </Grid>
              </InfoSection>
            </Box>

            <Divider sx={{ borderColor: 'rgba(0,0,0,0.1)' }} />

            {/* Password Reset Section */}
            <Box>
              <Stack direction="row" alignItems="center" spacing={2} mb={3}>
                <LockResetIcon sx={{ 
                  color: '#f8842b', 
                  fontSize: { xs: '24px', sm: '28px' } 
                }} />
                <Typography 
                  variant={isMobile ? "h6" : "h5"} 
                  fontWeight="600" 
                  color="#424242"
                >
                  {t('forgot_password')}
                </Typography>
              </Stack>

              <Stack spacing={3}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel sx={{ color: '#666' }}>{t('email')}</InputLabel>
                  <Select
                    value={email}
                    label={t('email')}
                    disabled
                    sx={{
                      backgroundColor: '#f5f5f5',
                      borderRadius: '10px',
                      fontSize: { xs: '0.9rem', sm: '1rem' }
                    }}
                  >
                    <MenuItem value={email}>{email}</MenuItem>
                  </Select>
                </FormControl>

                <StyledButton onClick={handlePasswordReset}>
                  <LockResetIcon />
                  {t('reset_password')}
                </StyledButton>
              </Stack>
            </Box>

            <Divider sx={{ borderColor: 'rgba(0,0,0,0.1)' }} />

            {/* Language Settings Section */}
            <Box>
              <Stack direction="row" alignItems="center" spacing={2} mb={3}>
                <LanguageIcon sx={{ 
                  color: '#f8842b', 
                  fontSize: { xs: '24px', sm: '28px' } 
                }} />
                <Typography 
                  variant={isMobile ? "h6" : "h5"} 
                  fontWeight="600" 
                  color="#424242"
                >
                  {t('language_settings')}
                </Typography>
              </Stack>

              <FormControl fullWidth variant="outlined">
                <InputLabel sx={{ color: '#666' }}>
                  {t('select_language')}
                </InputLabel>
                <Select
                  value={language}
                  onChange={handleLanguageChange}
                  label={t('select_language')}
                  sx={{
                    borderRadius: '10px',
                    fontSize: { xs: '0.9rem', sm: '1rem' },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(248,132,43,0.2)'
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#f8842b'
                    }
                  }}
                >
                  <MenuItem value="en">{t('english')}</MenuItem>
                  <MenuItem value="ar">{t('arabic')}</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>
        </CardContent>
      </StyledCard>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ 
          vertical: 'bottom', 
          horizontal: isMobile ? 'center' : 'right' 
        }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ 
            width: '100%',
            borderRadius: '10px',
            boxShadow: '0 4px 15px rgba(0,0,0,0.1)',
            fontSize: { xs: '0.85rem', sm: '1rem' }
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Settings;