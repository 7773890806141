import React, { useState } from 'react';
import { Box, Fab, Tooltip, Zoom } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { keyframes } from '@mui/system';
import { useTranslation } from 'react-i18next';

const FloatingSupportButton = () => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  const bounce = keyframes`
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  `;

  const pulse = keyframes`
    0% {
      box-shadow: 0 0 0 0 rgba(248, 132, 43, 0.4);
    }
    70% {
      box-shadow: 0 0 0 15px rgba(248, 132, 43, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(248, 132, 43, 0);
    }
  `;

  const handleSupportClick = () => {
    window.open('https://chat.cxgenie.ai?aid=4506fd38-76f9-4f14-9536-bbd43c76d75c&lang=ar', '_blank');
  };

  return (
    <Box>
      <Tooltip 
        title={t('need_help_chat')}
        placement="left"
        TransitionComponent={Zoom}
        arrow
        enterDelay={200}
        leaveDelay={0}
        sx={{
          '& .MuiTooltip-tooltip': {
            backgroundColor: '#2d3436',
            color: '#fff',
            fontSize: '0.9rem',
            padding: '8px 16px',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            maxWidth: '200px'
          },
          '& .MuiTooltip-arrow': {
            color: '#2d3436'
          }
        }}
      >
        <Fab
          onClick={handleSupportClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          sx={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            backgroundColor: '#f8842b',
            color: 'white',
            transition: 'all 0.3s ease',
            animation: `${pulse} 2s infinite`,
            '&:hover': {
              backgroundColor: '#e67422',
              animation: `${bounce} 1s ease infinite`,
              transform: 'scale(1.05)',
            },
            width: '65px',
            height: '65px',
            zIndex: 1000,
            boxShadow: '0 4px 15px rgba(248, 132, 43, 0.3)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SupportAgentIcon 
            sx={{ 
              fontSize: 35,
              transform: isHovered ? 'rotate(10deg)' : 'none',
              transition: 'transform 0.3s ease'
            }} 
          />
        </Fab>
      </Tooltip>
    </Box>
  );
};

export default FloatingSupportButton;
