import FeedbackSection from "./feedback/FeedbackSection";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import HowItWorks from "./how-it-works/How-it-works";
import Header from "./NavBar";
import Services from "./Services/Service";
import SliderSection from "./hero_section/SliderSection";
import WhatToBuySection from "./what-buy/what-buy";
import NewsletterSignupSection from "./Newslatter/newsletter_signup";
import FAQ from "./FAQ/faq";
import AnnouncementSection from "./Alerte/announcement";

const Homepage = ({ user }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="container py-20">
        <Helmet>
          <title>{t("home-screen")} </title>
        </Helmet>
        <div className="hero_area">
          <Header user={user} />
          <AnnouncementSection />
          <SliderSection />
        </div>
        <Services />
        <HowItWorks />
        <FeedbackSection />
        <WhatToBuySection />
        <FAQ />
        <NewsletterSignupSection />
      </div>
      <Footer />
    </div>
  );
};

export default Homepage;
