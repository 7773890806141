import AddIcon from '@mui/icons-material/Add';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Alert,
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Typography,
  Tooltip,
} from '@mui/material';
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { firestore } from '../../../config/firebaseConfig';
import {
  ContentWrapper,
  StyledButton,
  StyledPaper,
  StyledTextField,
} from '../Styles/User_Dashboard_Styles';

const OrderCard = ({ order }) => {
  const { t } = useTranslation();
  const [copySuccess, setCopySuccess] = useState(false);

  const getStatusColor = (status) => {
    switch(status.toLowerCase()) {
      case 'delivered': return '#4caf50';
      case 'in transit to germany': return '#ff9800' 
      case 'in our local in germany': return '#2196f3' 
      case 'in transit to tunisia': return '#ff9800' 
      case 'in our local in tunisia': return '#2196f3' 
      case 'pending': return '#ff9800';
      case 'cancelled': return '#f44336';
      default: return '#9e9e9e';
    }
  };
  const getStatusTranslation = (status) => {
    switch(status.toLowerCase()) {
      case 'delivered': return t('delivered');
      case 'in transit to germany': return t('in_transit_to_germany');
      case 'in our local in germany': return t('in_our_local_in_germany');
      case 'in transit to tunisia': return t('in_transit_to_tunisia');
      case 'in our local in tunisia': return t('in_our_local_in_tunisia');
      case 'pending': return t('pending');
      case 'cancelled': return t('cancelled');
      default: return t('unknown_status');
    }
  };

  const handleCopyTrackingCode = async () => {
    try {
      await navigator.clipboard.writeText(order.orderCode);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <StyledPaper
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '16px',
          overflow: 'hidden',
          boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
        }}
      >
        <Box sx={{ p: 3, backgroundColor: '#FFF3E0', color: '#E65100' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" fontWeight="700" gutterBottom>
              {t('order_from')} {order.site}
            </Typography>
            <Tooltip title={copySuccess ? t('copied') : t('copy_tracking_code')}>
              <IconButton 
                onClick={handleCopyTrackingCode}
                size="small"
                sx={{ 
                  color: copySuccess ? '#4caf50' : '#E65100',
                  '&:hover': { backgroundColor: 'rgba(230,81,0,0.1)' }
                }}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <Typography variant="body2" sx={{ opacity: 0.8 }}>
            {t('order_date')} {new Date(order.orderDate.toDate()).toLocaleDateString()}
          </Typography>
          
        </Box>
        <Box sx={{ flexGrow: 1, p: 3 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <Chip 
              label={getStatusTranslation(order.status)} 
              size="small"
              sx={{
                backgroundColor: getStatusColor(order.status),
                color: 'white',
                fontWeight: 600,
                padding: '8px 4px'
              }}
            />
            <Typography variant="h5" fontWeight="700" color="#f8842b">
              {order.priceInTnd} DT
            </Typography>
          </Box>
          <Box sx={{ 
            backgroundColor: 'rgba(248,132,43,0.05)', 
            borderRadius: '12px',
            p: 2,
            mb: 2
          }}>
            <Typography variant="body2" color="text.secondary" mb={2}>
              {t('First_Pay_Check')}: <strong>{order.clientFirstPaycheck} DT</strong>
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={2}>
              {t('Rest')}: <strong>{order.priceInTnd - order.clientFirstPaycheck} DT</strong>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {t('Payment')}: <strong>{order.paymentMethod}</strong>
            </Typography>
          </Box>
        </Box>
        
      </StyledPaper>
    </motion.div>
  );
};

const AddOrderDialog = ({ open, onClose, onSubmit }) => {
  const [orderCode, setOrderCode] = useState('');
  const { t } = useTranslation();

  const handleSubmit = () => {
    onSubmit(orderCode);
    setOrderCode('');
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      fullWidth 
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: '16px',
          boxShadow: '0 8px 32px rgba(0,0,0,0.1)'
        }
      }}
    >
      <DialogTitle sx={{ 
        backgroundColor: '#FFF3E0',
        color: '#E65100',
        fontWeight: 700
      }}>
        {t('add_order_to_account')}
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <StyledTextField
          autoFocus
          margin="dense"
          label={t('order_code')}
          fullWidth
          value={orderCode}
          onChange={(e) => setOrderCode(e.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px'
            }
          }}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2, backgroundColor: '#FFF3E0' }}>
        <StyledButton 
          variant="outlined" 
          onClick={onClose}
          color="inherit"
          sx={{ borderRadius: '8px' }}
        >
          {t('cancel')}
        </StyledButton>
        <StyledButton 
          onClick={handleSubmit}
          disabled={!orderCode.trim()}
          sx={{ 
            borderRadius: '8px',
            backgroundColor: '#f8842b',
            '&:hover': {
              backgroundColor: '#E65100'
            }
          }}
        >
          {t('add_order')}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

const UserOrders = ({ customerUid }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openAddOrder, setOpenAddOrder] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const { t } = useTranslation();

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const ordersCollection = collection(firestore, 'orders');
        const q = query(ordersCollection, where('customerUid', '==', customerUid));
        const querySnapshot = await getDocs(q);
        const ordersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setOrders(ordersList);
      } catch (err) {
        setError(t('fetch_orders_error'));
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [customerUid, t]);

  const handleAddOrder = async (orderCode) => {
    try {
      const ordersCollection = collection(firestore, 'orders');
      const q = query(ordersCollection, where('orderCode', '==', orderCode.trim()));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setSnackbar({ open: true, message: t('order_not_found'), severity: 'error' });
        return;
      }

      const orderDoc = querySnapshot.docs[0];
      const orderData = orderDoc.data();

      if (orderData.customerUid && orderData.customerUid !== "N/A") {
        setSnackbar({ open: true, message: t('order_already_associated'), severity: 'error' });
        return;
      }

      await updateDoc(doc(firestore, 'orders', orderDoc.id), { customerUid });

      setSnackbar({ open: true, message: t('order_added_success'), severity: 'success' });
      setOrders(prev => [...prev, { ...orderData, customerUid, id: orderDoc.id }]);
      setOpenAddOrder(false);
    } catch (err) {
      setSnackbar({ open: true, message: t('order_add_fail'), severity: 'error' });
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress sx={{ color: '#f8842b' }} />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error" sx={{ borderRadius: '12px' }}>{error}</Alert>;
  }

  return (
    <ContentWrapper>
      <Box 
        component={motion.div}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        display="flex" 
        justifyContent="space-between" 
        alignItems="center" 
        mb={4}
      >
        <Typography 
          variant="h4" 
          sx={{
            fontWeight: 700,
            color: '#f8842b',
            position: 'relative',
            '&:after': {
              content: '""',
              position: 'absolute',
              bottom: -8,
              left: 0,
              width: '60px',
              height: '4px',
              background: 'linear-gradient(90deg, #f8842b 0%, #ffb74d 100%)',
              borderRadius: '2px'
            }
          }}
        >
          {t('your_orders')}
        </Typography>
        <StyledButton
          startIcon={<AddIcon />}
          onClick={() => setOpenAddOrder(true)}
          sx={{
            backgroundColor: '#f8842b',
            '&:hover': {
              backgroundColor: '#E65100'
            },
            borderRadius: '12px',
            boxShadow: '0 4px 12px rgba(248,132,43,0.2)'
          }}
        >
          {t('add_order')}
        </StyledButton>
      </Box>

      <Grid container spacing={3}>
        {orders.map(order => (
          <Grid item xs={12} sm={6} md={4} key={order.id}>
            <OrderCard order={order} />
          </Grid>
        ))}
      </Grid>

      <AddOrderDialog
        open={openAddOrder}
        onClose={() => setOpenAddOrder(false)}
        onSubmit={handleAddOrder}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ 
            width: '100%',
            borderRadius: '12px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </ContentWrapper>
  );
};

export default UserOrders;